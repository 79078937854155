import React, { useState, useEffect } from "react"
import YouTube from "react-youtube"
import RegistrationForm from "../components/RegistrationForm"
import "../styles/home.css"

const VideoPlayer = () => {
  const [currentVideo, setCurrentVideo] = useState(0)
  const [showNext, setShowNext] = useState(false)
  const [showRegistration, setShowRegistration] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)

  const videos = [
    "Txd57hHRHlM",
    "6a7iaEg3gNI",
    "x1ipX7giH3M",
    "0XoruIal3_0",
    "5nsdD5T0sIk",
  ]

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  }

  const onEnd = (event) => {
    if (currentVideo < videos.length - 1) {
      setShowNext(true)
    } else {
      setShowRegistration(true)
    }
  }

  const onNext = () => {
    setButtonClicked(true)
    setTimeout(() => {
      setShowNext(false)
      setCurrentVideo(currentVideo + 1)
      setButtonClicked(false)
    }, 150) // match the transition duration
  }

  return (
    <div className="flex justify-center items-center w-full min-h-screen flex-col">
      {!showRegistration ? (
        <h1 className="p-5 text-xl text-center">
          BE SURE TO WATCH THE FOLLOWING VIDEOS PRIOR TO REGISTRATION
        </h1>
      ) : null}
      {!showRegistration ? (
        <div className="w-10/12 max-w-[800px] aspect-video h-full relative">
          <YouTube
            className="w-full h-full rounded-xl overflow-hidden"
            videoId={videos[currentVideo]}
            opts={opts}
            onEnd={onEnd}
          />
          <div className="flex justify-center items-center py-5 ">
            {showNext && (
              <div className="absolute top-0 left-0 w-full h-full bg-black rounded-xl flex justify-center items-center">
                <div className="relative w-48 h-16 ">
                  <button
                    className={`text-white font-bold text-xl uppercase bg-gradient-to-b from-green-600 to-green-500 w-full h-full absolute top-0 left-0 rounded-xl border-b border-b-green-200 z-10 transition-all hover:tracking-wider ${
                      buttonClicked ? "translate-y-2" : ""
                    }`}
                    onClick={onNext}
                  >
                    Next
                  </button>
                  <button
                    className="text-white font-bold bg-green-700 w-full h-full absolute top-0 left-0 rounded-xl translate-y-2 z-0"
                    onClick={onNext}
                  >
                    {""}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full max-w-[500px] fade-in">
          <RegistrationForm />
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
